import { Injectable, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserActivityService } from '@services/user-activity.service';
import { Game } from '@shared/types/game';
import { Theme } from '@shared/types/theme';
import { LottieFile } from '@shared/types/lottie-file';
import { UserInfo, ConfigValue } from '@longnecktech/splash-commons-fe';
import { Asset } from '@longnecktech/splash-commons-fe';
import { UserAction } from '@longnecktech/splash-commons-fe';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Tabs } from '@shared/types/tabs';
import { map, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _selectedTab = new BehaviorSubject<Tabs>(Tabs.PLAY);
  private _isMobile = new BehaviorSubject<boolean>(false);
  private _showWinnings = new BehaviorSubject<boolean>(false);
  private _awardAnimationPlayed = new BehaviorSubject<boolean>(false);
  private _icons = new BehaviorSubject<Asset[]>([]);
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  private _game = new BehaviorSubject<Game | undefined>(undefined);
  private _theme = new BehaviorSubject<Theme | undefined>(undefined);
  private _isMusicMuted = new BehaviorSubject<boolean>(false);

  thirdPartyToken = '';
  useLottie = false;
  isFromHub = false;
  hubGameUuid?: string;
  instance?: string;
  gameUuid?: string;
  spinMachineLottie: LottieFile | undefined;
  startTime = new Date().getTime();

  selectedTab$ = this._selectedTab.asObservable();
  isMobile$ = this._isMobile.asObservable();
  showWinnings$ = this._showWinnings.asObservable();
  awardAnimationPlayed$ = this._awardAnimationPlayed.asObservable();
  icons$ = this._icons.asObservable();
  user$ = this._user.asObservable();
  theme$ = this._theme.asObservable();
  isMusicMuted$ = this._isMusicMuted.asObservable();
  isGBCountry$ = this.user$.pipe(map((user) => user?.country === 'GB'));
  game$ = this._game.asObservable();
  noGame$ = this.game$.pipe(map((game) => !game));
  startGameUserAction = false;
  readonly thereAnimation$ = this.theme$.pipe(
    map((theme) => theme?.backgroundAnimation),
  );
  private destroyRef = inject(DestroyRef);

  constructor(
    private http: HttpClient,
    private userActivityService: UserActivityService,
    private router: Router,
  ) {}

  sendAction(action: UserAction, metadata: unknown): Observable<void> {
    return this.http.post<void>(environment.backendUrl + '/api/user/action', {
      action: action,
      gameUuid: this._game.value?.uuid,
      metadata,
    });
  }

  setGame(game: Game): void {
    this._theme.next(game.theme);
    this._game.next(game);
    this._icons.next(game.icons);
  }

  setTab(tab: Tabs): void {
    this._selectedTab.next(tab);
  }

  setIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  setShowWinnings(show: boolean): void {
    this._showWinnings.next(show);
  }

  setAwardAnimation(show: boolean): void {
    this._awardAnimationPlayed.next(show);
  }

  setUser(user: UserInfo): void {
    this._user.next(user);
  }

  getThemeLabels(): ConfigValue[] | undefined {
    return this._theme.value?.labels;
  }

  toggleMuted(isMuted?: boolean): void {
    this._isMusicMuted.next(isMuted ?? !this._isMusicMuted.value);
  }

  updateAuthToken(): void {
    // Run every 10 minutes
    interval(10 * 60 * 1000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // this interval must not refresh the token trigger if the user is not active
        filter(() => !this.userActivityService.isUserInactive()),
        switchMap(() => this.fetchToken()),
      )
      .subscribe({
        next: (user) => {
          this.setUser(user);
        },
        error: () => {
          this.router.navigate(['/error']);
        },
      });
  }

  fetchToken(): Observable<UserInfo> {
    return this.http.post<UserInfo>(
      `${environment.backendUrl}/api/public/auth/token`,
      {
        token: this.thirdPartyToken,
        instance: this.instance,
      },
    );
  }
}
