import { Injectable, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge, fromEvent, map, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private readonly destroyRef = inject(DestroyRef);
  private lastActiveTs = Date.now();
  private readonly events = ['mousemove', 'keydown', 'scroll', 'click'];

  constructor() {
    this.initActivityTracking();
  }

  private initActivityTracking() {
    const activityEvents = this.events.map((event) =>
      fromEvent(document, event),
    );

    merge(...activityEvents)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(300), // prevent too many updates
        map(() => this.updateLastActiveTimestamp()),
      )
      .subscribe();
  }

  private updateLastActiveTimestamp(): number {
    return (this.lastActiveTs = Date.now());
  }

  // to check if user has been inactive for more than 10 minutes
  isUserInactive(): boolean {
    const inactivityThreshold = 10 * 60 * 1000;
    return Date.now() - this.lastActiveTs > inactivityThreshold;
  }
}
